<style scoped lang="scss">
@import './TripCardStyle.scss';
</style>

<template>
  <div class="card relative h-400 overflow-hidden br-14 wp-100" style="-webkit-backface-visibility: hidden; -webkit-transform: translate3d(0, 0, 0)">
    <ClientOnly fallbackTag="div">
      <LazyLoad when-visible class="card-block&#45;&#45;image el-cover overflow-hidden br-14" :distance="0">
        <BaseResponsiveImages :src="cardData.image" :alt="cardData.name" />
      </LazyLoad>
      <template #fallback>
        <BaseResponsiveImages :src="cardData.image" :alt="cardData.name" />
      </template>
    </ClientOnly>
<!--    <LazyLoad when-visible class="card-block&#45;&#45;image el-cover overflow-hidden br-14" :distance="0">
      <BaseResponsiveImages :src="cardData.image" :alt="cardData.name" />
    </LazyLoad>-->
      <router-link
        class="absolute z-2 top-0 left-0 wp-100 hp-100 bg-linear-gradient hand db br-14"
        :to="{ path: goToDetails(cardData.url) }"
        :target="targetTab()"
        :aria-label="`Read more about ${cardData.name}`"
      ></router-link>
      <router-link :to="{ path: goToDetails(cardData.url) }" :target="targetTab()">
        <div class="absolute z-4 top-0 left-0 wp-100 white pt-20 px-20">
          <h3 class="truncate fs-28 overflow-hidden leading-none lh-1 ma-0 hand">
            <span class="white fw-900">{{ cardData.name }}</span>
            <!-- <nuxt-link :to="{ path: goToDetails(cardData.url) }" :target="targetTab()"
              ><span class="white fw-900">{{ cardData.name }}</span></nuxt-link
            > -->
          </h3>
          <div class="mt-12 mb-12">
            <img width="15" height="13" alt="Pricing icon" src="https://s3-cdn.designerjourneys.com/static/vectors/general/card-price.svg" />
            <span class="fs-16 ml-8"
              >From <span class="fs-20 fw-700">{{ calculatedPrice }}</span></span
            >
          </div>
<!--          <div class="flex flex-column items-start">
            <div class="mb-5" v-for="(item, i) in listBadge" :key="i">
              <Badge
                typeBadge="custom"
                :badgeName="item.name"
                :tooltipText="`${item.description} <a id=&quot;termModal&quot;><span class=&quot;underline black hand&quot;>Terms and conditions</span></a>.`"
                @clickTooltip="toggleModalTerms(item.name, item.terms)"
              />
            </div>
          </div>-->
        </div>
        <div class="absolute z-3 bottom-20 left-0 wp-100 white px-20 flex justify-between hand items-center" @click="goToTripPlan()">
          <TravellerReview :travellerReview="cardData.designer" :target="'_blank'" />
        </div>
      </router-link>
  </div>
  <AppModal :show="modalTermsActive" :showFooter="false" @closeModal="handleCloseModal" class="modal-term">
    <template #header>
      <div>
        <div class="dib black fs-16 lh-16 fw-700 mr-15">Terms and Conditions</div>
      </div>
    </template>
    <template #body>
      <div class="modalContent">
        <div class="fw-700" v-html="modalTermsTitle"></div>
        <div v-html="modalTermsContent"></div>
      </div>
    </template>
  </AppModal>
</template>

<script setup>
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { CurrencyToUSD, formatCurrency, USDToCurrency } from '~/helper'
import LazyLoad from '~/components/ui/lazy-load/LazyLoad'
import TravellerReview from '~/components/ui/traveller-review/TravellerReview'
import BaseResponsiveImages from './ResponsiveImages.vue'
import AppModal from '~/components/ui/modal/AppModal'

  const props = defineProps({
  cardData: Object,
  freeCancel: Boolean,
  // tooltipText: String,
})

    const store = useStore()
    const router = useRouter()
    const {isMobile} = useDevice()

    const currencySelected = computed(() => store.state.currenciesStore.currencySelected)
    const currenciesData = computed(() => {
      return store.state.currenciesStore.currenciesData
    })
    const currencyChangeByUser = computed(() => store.state.currenciesStore.currencyChangeByUser)
    // Convert orginal price to USD
    const originalPriceByUSD = computed(() => {
      const { rate } = currenciesData.value?.find(({ code }) => code === props.cardData.currency)
      return CurrencyToUSD(rate, props.cardData.price, false)
    })

    // const specialDeals = computed(() => store.state.generalStore.generalData.specialDeals)
    // const listBadge = computed(() => {
    //   return cloneDeep(
    //     specialDeals.value.filter((item) => {
    //       return props.cardData.deal_ids.find((id) => item.id === id)
    //     }),
    //   )
    // })

    const calculatedPrice = computed(() => {
      const { rate, code, symbol } = currencySelected.value

      let priceFinal = ''
      // Update price if user change currency and difference from 'display_currency'
      if (code !== props.cardData.display_currency) {
        priceFinal = formatCurrency(
          USDToCurrency({
            rate: rate,
            volume: originalPriceByUSD.value,
            currency: code,
          }),
          symbol,
        )
      } else {
        priceFinal = formatCurrency(props.cardData.display_price, props.cardData.display_currency_symbol)
      }
      return priceFinal
    })

    const tooltipText = (description) => {
      return `${description} '<a id="termModal"><span class="underline black">Terms and conditions</span></a>.'`
    }

    const goToDetails = (url) => {
      return `/trip-gallery/${url}`
    }

    const targetTab = () => {
      if (isMobile) return '_self'
      else return '_blank'
    }

    const modalTermsActive = ref(false)
    const modalTermsContent = ref('')
    const modalTermsTitle = ref('')

    const toggleModalTerms = (title, content) => {
      modalTermsTitle.value = title
      modalTermsContent.value = content
      modalTermsActive.value = !modalTermsActive.value
    }

    const handleCloseModal = (val) => {
      modalTermsActive.value = val
    }

    const goToTripPlan = () => {
      const { href } = router.resolve({
        path: goToDetails(props.cardData.url),
      })
      window.open(href, targetTab())
    }
</script>
<style scoped lang="scss">
.modalContent {
  :deep(div) {
    * {
      font-size: 16px;
      line-height: 24px !important;
    }
    a {
      text-decoration: underline !important;
      color: black;
    }
  }
  div:first-child {
    margin-bottom: 30px;
  }
  //:deep(ol) {
  //  li {
  //    margin-top: 15px;
  //    span {
  //      font-family: $font-family-proximanova-black;
  //      font-weight: 900;
  //      color: $blue-branding;
  //      margin-right: 12px;
  //      display: inline-block;
  //      font-size: 20px;
  //      line-height: 28px;
  //    }
  //    div {
  //      line-height: 24px;
  //      font-size: 16px;
  //    }
  //    .flex div {
  //      padding-top: 2px;
  //    }
  //  }
  //  &.popup-term {
  //    a {
  //      text-decoration: underline !important;
  //      color: black;
  //    }
  //    li {
  //      >p {
  //        font-size: 16px;
  //        font-weight: 700;
  //        font-family: proximanova-bold;
  //      }
  //      span {
  //        font-weight: 700; font-family: 'proximanova-bold';
  //        color: $blue-branding;
  //        margin-right: 12px;
  //        display: inline-block;
  //        font-size: 16px;
  //        line-height: 16px;
  //        padding-top: 6px;
  //      }
  //      div {
  //        line-height: 24px;
  //        font-size: 16px;
  //      }
  //
  //    }
  //  }
  //}
}
</style>
